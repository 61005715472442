<template>
  <div class="">
    <div class="d-flex justify-content-end mb-2">
      <div class="mr-2 d-inline-flex flex-column">
        <label><b>Show Hidden</b></label>
        <DxSwitch v-model="showHidden" :key="showHidden" />
      </div>
    </div>
    <div class="grid-wrapper">
      <dx-grid-with-search
        :dataSource="dataSource"
        :columns="columns"
        title="Distribution History"
        gridName="caseDistributions"
        :noDataText="'No distribution history found.'"
        @initialized="initializeGrid"
      >
        <template v-slot:actions-cell="{ data }">
          <div class="d-flex align-items-center">
            <icon-button
              @click="getPathReportPDf(data)"
              class="btn text-primary p-0"
              v-tooltip.left="'View PDF'"
              icon="eye"
            />
            <icon-button
              v-tooltip.right="'Resubmit accession.'"
              class="mx-2 btn text-warning pointer p-0"
              @click="resubmitMessage(data)"
              icon="redo"
            />
            <icon-button
              v-if="data && data.data.messageStatus === 'Pending'"
              class="btn text-danger pointer p-0"
              icon="ban"
              @click="cancelMessage(data)"
              v-tooltip.left="'Cancel distribution'"
            />
          </div>
        </template>
      </dx-grid-with-search>
    </div>
    <modal :status="isViewerOpen" @close="isViewerOpen = false">
      <PDFViewer class="viewer" :url="pdfUrl" @close="isViewerOpen = false" />
    </modal>
  </div>
</template>

<script>
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import Modal from "./common/Modal.vue";
import PDFViewer from "./common/PDFViewer.vue";
import PathReportMessage from "../services/pathReportMessages";
import Reports from "../services/Reports";
import { mapState } from "vuex";
import {
  createLocalTzDateFilter,
  createLogItem,
  filterAccessionNumber,
  filterCellUTC,
  formatDatetimeCell
} from "../modules/helpers";
import DataSource from "devextreme/data/data_source";
import IconButton from "./common/IconButton.vue";
import DxSwitch from "devextreme-vue/switch";
import { AuditLogApi } from "@/services";
import { AuditLogItems } from "@/modules/enums";
import { format } from "date-fns";

export default {
  name: "Accession-Distribution",
  components: { DxGridWithSearch, Modal, PDFViewer, IconButton, DxSwitch },
  data() {
    return {
      isViewerOpen: false,
      pdfUrl: "",
      startDate: "",
      endDate: new Date(),
      remoteOperations: {
        paging: true,
        filtering: true,
        sorting: true
      },
      showHidden: false
    };
  },
  created() {
    this.refreshDistributions();
  },
  methods: {
    initializeGrid({ component }) {
      this.grid = component;
    },
    getPathReportPDf({ data }) {
      const { pathReportId } = data;
      Reports.getPathReportById(pathReportId).then(pdf => {
        const blob = new Blob([pdf], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
        this.isViewerOpen = true;
      });
    },
    async resubmitMessage({ data }) {
      const { pathReportMessageId } = data;
      const confirm = await window.confirm(
        `<p class="text-center m-auto">The accession will be resent by this distribution method in its current state not in the state it was sent in when it was sent previously.<br>
        Are you sure you want to resend?</p>`
      );
      if (!confirm) {
        return;
      }
      return PathReportMessage.resubmitMessage(pathReportMessageId).then(() => {
        window.notify("Success!");
        this.grid.refresh();
        const logItem = createLogItem(
          { caseNumber: data.caseNumber },
          AuditLogItems[data.messageType]
        );
        logItem.comments = `Resubmitted ${data.messageType} message with ID of ${pathReportMessageId}.`;
        AuditLogApi.insertLogMessage(logItem);
      });
    },
    cancelMessage({ data }) {
      const { pathReportMessageId } = data;
      return PathReportMessage.cancelMessage(pathReportMessageId).then(() => {
        window.notify("Success!");
        this.grid.refresh();
      });
    },
    refreshDistributions() {
      const { caseId } = this.$route.params;
      this.$store.dispatch("accessionStore/getDistributions", caseId);
    }
  },
  computed: {
    ...mapState({
      pathReportMessages: state => state.accessionStore.caseDistributions
    }),
    dataSource() {
      return new DataSource({
        store: this.showHidden
          ? this.pathReportMessages
          : this.pathReportMessages.filter(e => e.hideInDistributionHistory === false)
      });
    },
    columns() {
      return [
        {
          dataField: "messageType",
          dataType: "string"
        },
        {
          dataField: "messageStatus",
          dataType: "string"
        },
        {
          dataField: "caseNumber",
          dataType: "string",
          caption: "Case #",
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },

        {
          dataField: "recipient",
          dataType: "string"
        },
        {
          dataField: "destination",
          dataType: "string"
        },
        {
          dataField: "sentOn",
          dataType: "datetime",
          sortOrder: "asc",
          sortIndex: 1,
          allowFiltering: true,
          calculateCellValue(data) {
            if (data.sentOn) {
              return formatDatetimeCell(data.sentOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("sentOn")
        },
        {
          dataField: "submittedBy",
          dataType: "string"
        },
        {
          dataField: "submittedOn",
          dataType: "date",
          calculateCellValue(data) {
            if (data.submittedOn) {
              return formatDatetimeCell(data.submittedOn);
            }
            return "";
          }
        },
        {
          dataField: "errorMessage",
          dataType: "string"
        },
        {
          dataField: "failedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.failedOn) {
              const formattedDate = formatDatetimeCell(data.failedOn);
              return format(formattedDate, "M/dd/yyyy");
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "failedOn");
          }
        },
        {
          dataField: "cancelledBy",
          dataType: "string"
        },
        {
          dataField: "cancelledOn",
          dataType: "datetime",
          calculateCellValue(data) {
            if (data.cancelledOn) {
              return formatDatetimeCell(data.cancelledOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("cancelledOn")
        },
        {
          dataField: "faxStartedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.faxStartedOn) {
              const formattedDate = formatDatetimeCell(data.faxStartedOn);
              return format(formattedDate, "M/dd/yyyy");
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "faxStartedOn");
          }
        },
        {
          dataField: "contact",
          dataType: "string"
        },
        {
          dataField: "copyName",
          dataType: "string"
        },
        {
          dataField: "caseStatus",
          dataType: "string"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions-cell"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .viewer {
  width: 42.5vw;
}
</style>
